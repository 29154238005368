/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.installer-outer {
  flex: 1;
  justify-content: center;
  display: flex;
  background-color: #19D1C6;
}
.installer-outer .installer {
  background-color: #fff;
  align-self: center;
  width: 500px;
  padding: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  color: black;
}
.installer-outer .installer .login-logo {
  width: 120px;
  padding-left: 0px;
  margin-bottom: 20px;
}
.installer-outer .installer .comeos-button {
  color: #000;
  display: inline-block;
  background-color: transparent;
  padding: 10px;
  /* height: 40px; */
  border-radius: 40px;
  border: 2px solid #000;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  transition: background-color 100ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 20px;
  text-decoration: none;
}
.installer-outer .installer h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
.installer-outer .installer h2 {
  font-size: 16px;
  margin-bottom: 10px;
}
.installer-outer .installer p {
  color: black;
}
.installer-outer .installer .form-row {
  display: flex;
  margin-bottom: 10px;
}
.installer-outer .installer .form-row .link {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.installer-outer .installer .form-row .link a {
  color: #000;
  text-decoration: none;
}
.installer-outer .installer .form-row .link a:hover {
  text-decoration: underline;
}
.installer-outer .installer .form-row .label {
  width: 150px;
  align-self: center;
}
.installer-outer .installer .form-group {
  margin-bottom: 10px;
}
.installer-outer .installer input {
  flex: 1;
  padding: 5px;
  border: 2px solid;
  border-radius: 20px;
  color: #000000;
}
@media screen and (max-width: 768px) {
  .installer-outer .installer {
    width: 100%;
    max-width: 100%;
  }
}
